/* Navbar.css */

.nav-link {
  color: #3A3A3A;
  /* Default text color */
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #1F3A68;
  /* Hover color */
  font-weight: bold;
}

.nav-link.active {
  color: #1F3A68;
  /* Active link color */
  font-weight: bold;
}