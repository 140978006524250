/* Container styles */
.container {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

/* Heading styles */
.h1 {
    font-family: Roboto, sans-serif;
    font-size: 4rem;
    /* Responsive unit */
    color: #004581;
    text-align: center;
    font-weight: bold;
}

.h2 {
    font-family: Roboto, sans-serif;
    font-size: 1.6875rem;
    /* 27px */
    color: #C51414;
    text-align: center;
}

/* Caption styles */
.caption {
    font-family: Roboto, sans-serif;
    font-size: 1.4375rem;
    /* 23px */
    color: #a8b1b8;
    text-align: center;
    font-weight: 400;
    margin: 1.25rem 1.5rem;
    /* Adjusted margins */
}

/* Register link */
.register,
.link {
    font-family: Roboto, sans-serif;
    font-size: 1.1875rem;
    /* 19px */
    text-align: center;
}

.register {
    color: #a8b1b8;
    font-weight: 400;
}

.link {
    color: #018ABD;
    font-weight: bold;
}

/* Label styles */
.label {
    font-family: Roboto, sans-serif;
    font-size: 1.25rem;
    /* 20px */
    color: #495057;
    text-align: left;
    font-weight: 600;
}

/* Input styles */
.input {
    background: #F8F9FA;
    width: 100%;
    max-width: 24rem;
    /* 384px */
    height: 4rem;
    /* 64px */
    padding: 0 1.25rem;
    /* 20px */
    border-radius: 0.375rem;
    /* 6px */
}

/* Forgot password link */
.forgot {
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    /* 14px */
    color: #004581;
    text-align: right;
    font-weight: 600;
    margin-top: 0.625rem;
    /* 10px */
    margin-right: 1.5rem;
    /* Adjusted for responsiveness */
}

/* Button styles */
.button {
    background-color: #018ABD;
    font-family: Roboto, sans-serif;
    color: white;
    font-weight: bold;
    font-size: 1.25rem;
    /* 20px */
    width: 100%;
    max-width: 21.6875rem;
    /* 347px */
    height: 4.3125rem;
    /* 69px */
    border-radius: 0.375rem;
    /* 6px */
}

/* Error message */
.error {
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    /* 16px */
    color: red;
    text-align: center;
    font-weight: 200;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    .h1 {
        font-size: 2.5rem;
        /* Adjusted for mobile */
    }

    .h2 {
        font-size: 1.25rem;
        /* Adjusted for mobile */
    }

    .caption {
        font-size: 1rem;
        margin: 1rem;
    }

    .input,
    .button {
        max-width: 100%;
    }
}