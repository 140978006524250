* {
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
    margin: 0;
    padding: 0;
}

.results-container {
    width: 100%;
    min-height: 100vh;
    padding: 20px;
}

.results-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
}

.results-title {
    font-size: 1.5rem;
    color: #004581;
    font-weight: bold;
    margin-bottom: 10px;
}

.results-search {
    width: 100%;
}

@media (min-width: 768px) {
    .results-header {
        flex-direction: row;
        align-items: center;
    }

    .results-search {
        width: 30%;
    }
}

.results-table-container {
    overflow-x: auto;
}

.results-table {
    width: 100%;
    border-collapse: collapse;
}

.table-header {
    background-color: #004581;
    color: white;
    padding: 10px;
    text-align: center;
    font-size: 1rem;
}

.table-row {
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.table-row:hover {
    background-color: #f0f0f0;
}

.table-cell {
    padding: 10px;
    text-align: center;
    font-size: 0.9rem;
    color: #333;
}

.action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 768px) {
    .action-buttons {
        flex-direction: row;
        justify-content: center;
    }

    .action-buttons .action-button:not(:last-child) {
        margin-right: 10px;
    }
}

.action-button {
    display: flex;
    align-items: center;
    color: #004581;
    font-size: 0.9rem;
    margin-bottom: 10px;
    text-decoration: none;
}

.action-button:hover {
    text-decoration: underline;
}

.action-button .icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.no-results {
    padding: 20px;
    text-align: center;
    font-size: 1rem;
    color: #666;
}

/* Responsive adjustments */

@media (max-width: 480px) {

    .table-header,
    .table-cell {
        padding: 8px;
        font-size: 0.8rem;
    }

    .results-title {
        font-size: 1.25rem;
    }
}