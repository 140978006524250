@tailwind base;
@tailwind components;
@tailwind utilities;

/* 1. Set height and reset margins and padding */
html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* 2. Optionally set box-sizing globally */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.remove-number-input-arrow::-webkit-inner-spin-button,
.remove-number-input-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-number-input-arrow {
  -moz-appearance: textfield;
}