.App {
  text-align: center;
  padding: 0vmin;
}

.App-logo {
  height: 30vmin;
  max-height: 150px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: clamp(16px, calc(10px + 2vmin), 24px);
  color: white;
  padding: 5vmin;
  box-sizing: border-box;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.socketline {
  border: 1px solid black;
  margin: 5vmin auto;
  width: 90%;
  max-width: 700px;
}

@media (max-width: 768px) {
  .App-header {
    font-size: clamp(14px, calc(8px + 2vmin), 20px);
  }

  .socketline {
    width: 95%;
  }
}

@media (max-width: 480px) {
  .App-header {
    font-size: clamp(12px, calc(6px + 2vmin), 18px);
  }

  .socketline {
    width: 100%;
    margin: 3vmin auto;
  }
}