/* Device.css */
/*progbar styling*/
* {
    font-family: Roboto, sans-serif;
}

.step-item {
    /* padding-left: 110px; */
    display: flex;
        flex-direction: row;
        align-items: top;
        justify-content: flex-start;
        /* Align items to the start */
        padding-left: 20px;
    /*@apply relative flex flex-col justify-center items-center w-36;*/
}

.navmenu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
}

.line {
    margin-top: 0px;
    margin-bottom: 0px;
    border-left: 2px solid #C8C8C8;
    height: 90px;
    /*change this value to change height of menu*/
}

.step {
    width: 23px;
    height: 23px;
    border-radius: 9999px;
    border-color: #C8C8C8;
    background-color: transparent;
    border-width: 3px;
    margin-top: 0px;
}

.step::before {
    content: "";
    position: absolute;
    width: 3px;
    height: calc(100%-58px);
    top: 0;
    left: 40px;
    background: #018ABD;
    z-index: -1;

}

.proglabel {
    font-family: Roboto, sans-serif;
    font-size: 21px;
    color: #C8C8C8;
    font-weight: bold;
}

.progcaption {
    font-family: Roboto, sans-serif;
    font-size: 13px;
    color: #C8C8C8;
    font-weight: bold;
    margin-left: 0px;
    margin-top: 0px;
}

.inactive {
    .step {
        border-color: #C8C8C8;
        background-color: transparent;
    }

    .proglabel {
        color: #C8C8C8;
    }

    .progcaption {
        color: #C8C8C8;
    }

    .line {
        border-left: 2px solid #C8C8C8;
    }
}

.current {
    .step {
        border-color: #1F3A68;
        background-color: transparent;
    }

    .proglabel {
        color: #1F3A68;
    }

    .progcaption {
        color: #1F3A68;
    }

    .line {
        border-left: 2px solid #C8C8C8;
    }
}

.incorrect {
    .step {
        border-color: #F03D3E;
        background-color: #F03D3E;
    }

    .proglabel {
        color: #F03D3E;
    }

    .progcaption {
        color: #F03D3E;
    }

    .line {
        border-left: 2px solid #C8C8C8;
    }

}
.input-field {
    width: 100%;
    /* Makes input field take full width of its container */
}
.complete {
    .step {
        background-color: #1F3A68;
        border-color: #1F3A68;
    }

    .proglabel {
        color: #1F3A68;
        font-weight: bold !important;
    }

    .progcaption {
        color: #1F3A68;
    }

    .line {
        border-left: 2px solid #1F3A68;
    }
}

/*.complete:not(:first-child):before,.active:not(:first-child):before {
    background-color: #00ff59;
}*/

/*home page*/
.sndhalf {
    margin-left: 35%;
}

/*device page styling*/
.bg {
    background-color: #E9F6FD;
}

.rectangle {
    width: 95%;
    margin: 0 5%;
    background-color: transparent;
    padding: 20px;
}

.titleblock {
    /*width: 595px;*/
    width: 95%;
    background-color: transparent;
    border-bottom-color: #828282;
    border-bottom-width: 1px;

}

.titletxt {
    color: black;
    font-size: clamp(2rem, 5vw, 3rem);/*50px;*/
    text-align: center;
    font-weight: bold;
    /*padding-top: 24px;
    padding-bottom: 24px;*/
    padding: 2% 0;
}

.style1 {
    padding-top: 400px;
    margin-bottom: 10%;
}
* {
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
}


/* Adjusted classes for responsive design */

@media (max-width: 768px) {

    /* Adjust font sizes for smaller screens */
    .temptext {
        font-size: 1.25rem;
    }
}

@media (min-width: 769px) {

    /* Adjust font sizes for larger screens */
    .temptext {
        font-size: 2rem;
    }
}

/* Example of custom class if needed */
.temptext {
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
}

/* If you have specific styles that cannot be replaced by Tailwind, adjust them here */