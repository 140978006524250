.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #1F3A68;
    border-radius: 50%;
    width: clamp(20px, 4vw, 24px);
    height: clamp(20px, 4vw, 24px);
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}